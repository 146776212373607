import React from 'react';
import css from './FooterNew.module.css';
import logo from '../../../assets/logo.png';
import { CiMail } from 'react-icons/ci';
import { IoCallOutline } from 'react-icons/io5';
import { NamedLink } from '../../../components';
const FooterNew = () => {
  return (
    <div className={css.root}>
      <div className={css.footer}>
        <div className={css.left}>
          <img src={logo} alt="logo" className={css.logo} />
          {/* <p className={css.text}>Helping Kreators & Businesses Thrive</p> */}
        </div>
        <div className={css.right}>
          <div className={css.contact}>
            <div className={css.contactItem}>
              <CiMail className={css.icon} />
              <span>
                <a href="mailto:info@ukreate.com" className={css.link}>
                  info@ukreate.com
                </a>
              </span>
            </div>
            <div className={css.contactItem}>
              <IoCallOutline className={css.icon} />
              <span>
                <a href="tel:+1(856)600-2347" className={css.link}>
                  +1(856)600-2347
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className={css.footerBottom}>
        <div className={css.footerCopyRight}>
          <p className={css.copyRight}>© Copyright 2024</p>
          <div className={css.copyRight}>
            <NamedLink name="TermsOfServicePage" className={css.linkText}>
              Terms of Service
            </NamedLink>
            |
            <NamedLink name="PrivacyPolicyPage" className={css.linkText}>
              Privacy Policy
            </NamedLink>
            <span className={css.desktop}>
              |
              <NamedLink
                name="CMSPage"
                params={{ pageId: 'acceptable-use-policy' }}
                className={css.linkText}
              >
                Acceptable Use Policy
              </NamedLink>
            </span>
            <span className={css.mobile}>
              <NamedLink
                name="CMSPage"
                params={{ pageId: 'acceptable-use-policy' }}
                className={css.linkText}
              >
                Acceptable Use Policy
              </NamedLink>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterNew;
