import React, { useEffect, useRef } from 'react';
import { array, bool, func, number, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { propTypes } from '../../util/types';
import {
  sendVerificationEmail,
  hasCurrentUserErrors,
  CLOSE_PAY_OUT_DETAILS_MODAL,
  SET_SHOW_MODAL,
  SET_INTERVAL_TIME,
  setSignUpPopup,
} from '../../ducks/user.duck';
import { logout, authenticationInProgress } from '../../ducks/auth.duck';
import { manageDisableScrolling } from '../../ducks/ui.duck';
import { Modal, Topbar } from '../../components';
import SignupPop from '../../components/ModalMissingInformation/SignupPop';

export const TopbarContainerComponent = props => {
  const {
    authInProgress,
    currentPage,
    currentSearchParams,
    currentUser,
    currentUserHasListings,
    currentUserHasOrders,
    history,
    isAuthenticated,
    authScopes,
    hasGenericError,
    location,
    notificationCount,
    onLogout,
    onManageDisableScrolling,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    onResendVerificationEmail,
    isPayoutDetailsMissing,
    onClosePayoutMissingModal,
    isLandingPage = false,
    currentUserLoading,
    signUpPopup,
    showModal,
    intervalTime,
    onShowModal,
    onSetIntervalTime,
    createGigPopup,
    onCloseCreateListingPopUp,

    ...rest
  } = props;
  const isInitialMount = useRef(true);

  // const functionSetInterval = intervalTime => {
  //   if (signUpPopup) {
  //     console.log('set interval', intervalTime);
  //     setTimeout(() => {
  //       onShowModal(true);
  //     }, intervalTime);
  //   }
  // };
  // useEffect(() => {
  //   if (isInitialMount.current) {
  //     isInitialMount.current = false;
  //   } else {
  //     if (signUpPopup) {
  //       console.log('sign up popup', signUpPopup);
  //       functionSetInterval(10000);
  //     }
  //   }
  // }, [signUpPopup]);

  return (
    <>
      <Topbar
        authInProgress={authInProgress}
        currentPage={currentPage}
        currentSearchParams={currentSearchParams}
        currentUser={currentUser}
        currentUserHasListings={currentUserHasListings}
        currentUserHasOrders={currentUserHasOrders}
        history={history}
        isAuthenticated={isAuthenticated}
        authScopes={authScopes}
        location={location}
        notificationCount={notificationCount}
        onLogout={onLogout}
        onManageDisableScrolling={onManageDisableScrolling}
        onResendVerificationEmail={onResendVerificationEmail}
        sendVerificationEmailInProgress={sendVerificationEmailInProgress}
        sendVerificationEmailError={sendVerificationEmailError}
        showGenericError={hasGenericError}
        isPayoutDetailsMissing={isPayoutDetailsMissing}
        onClosePayoutMissingModal={onClosePayoutMissingModal}
        onCloseCreateListingPopUp={onCloseCreateListingPopUp}
        isLandingPage={isLandingPage}
        currentUserLoading={currentUserLoading}
        createGigPopup={createGigPopup}
        {...rest}
      />
      {/* {showModal && signUpPopup ? (
        <Modal
          {...props}
          isOpen={showModal && signUpPopup}
          onClose={() => {
            functionSetInterval(30000);
            onShowModal(false);
          }}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <SignupPop />
        </Modal>
      ) : null} */}
    </>
  );
};

TopbarContainerComponent.defaultProps = {
  currentPage: null,
  currentSearchParams: null,
  currentUser: null,
  currentUserHasOrders: null,
  notificationCount: 0,
  sendVerificationEmailError: null,
  authScopes: null,
};

TopbarContainerComponent.propTypes = {
  authInProgress: bool.isRequired,
  currentPage: string,
  currentSearchParams: object,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool.isRequired,
  currentUserHasOrders: bool,
  isAuthenticated: bool.isRequired,
  authScopes: array,
  notificationCount: number,
  onLogout: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  onResendVerificationEmail: func.isRequired,
  hasGenericError: bool.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({ state: object }).isRequired,
};

const mapStateToProps = state => {
  // Topbar needs isAuthenticated
  const { isAuthenticated, logoutError, authScopes } = state.auth;
  // Topbar needs user info.
  const {
    currentUser,
    currentUserHasListings,
    currentUserHasOrders,
    currentUserNotificationCount: notificationCount,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    isPayoutDetailsMissing,
    currentUserLoading,
    signUpPopup,
    showModal,
    intervalTime,
    createGigPopup,
  } = state.user;
  const hasGenericError = !!(logoutError || hasCurrentUserErrors(state));
  return {
    authInProgress: authenticationInProgress(state),
    currentUser,
    currentUserHasListings,
    currentUserHasOrders,
    notificationCount,
    isAuthenticated,
    authScopes,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    hasGenericError,
    currentUserLoading,
    createGigPopup,
    isPayoutDetailsMissing,
    signUpPopup,
    showModal,
    intervalTime,
  };
};

const mapDispatchToProps = dispatch => ({
  onLogout: historyPush => dispatch(logout(historyPush)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
  onShowModal: modal => dispatch({ type: SET_SHOW_MODAL, payload: modal }),
  onSetIntervalTime: time => dispatch({ type: SET_INTERVAL_TIME, payload: time }),
  onCloseCreateListingPopUp: () => dispatch(setSignUpPopup(false)),
  onClosePayoutMissingModal: () =>
    dispatch({
      type: CLOSE_PAY_OUT_DETAILS_MODAL,
    }),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const TopbarContainer = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(TopbarContainerComponent);

export default TopbarContainer;
