import React from 'react';
import css from './ModalMissingInformation.module.css';
import NamedLink from '../NamedLink/NamedLink';
import Button from '../Button/Button';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch } from 'react-redux';
import { setSignUpPopup } from '../../ducks/user.duck';

const CreateGigPopup = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const handleSubmit = () => {
    history.push('/l/new');
    dispatch(setSignUpPopup(false));
  };
  return (
    <div className={css.popupContainer}>
      <p className={css.modalMessage}>
        Awesome! Now that you have an account with ukreate, go here to create a Gig. This is an
        important step – it’s where you tell Brands what you’re offering.
        <br />
        <br /> REMINDER: you can create as many 'gigs' as you want , in fact we recommend it!
      </p>
      <Button style={{ marginTop: '1rem' }} onClick={handleSubmit}>
        Click Here!
      </Button>
    </div>
  );
};

export default CreateGigPopup;
