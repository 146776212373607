
export const cookieConfig = {
  onChange: ()=> {
   window.location.reload()
  },
  mode: 'opt-in',
    categories: {
        necessary: {
            enabled: true,  // this category is enabled by default
            readOnly: true  // this category cannot be disabled
        },
      //   analytics: {
      //     enabled: true,
      //     services: {
      //         GoogleAnalytics: {
      //           label: 'GoogleAnalytics',
      //           onAccept: () => {
                  
      //           },
      //           onReject: () => {
      //             window[`ga-disable-${googleAnalyticsId}`] = true;
      //           },
      //           cookies: [
      //             {
      //               name: /^(_ga)/,                  }
      //             ]
      
      //         },
      //         FacebookPixel: {
      //           // enabled: true,
      //           label: 'Meta Pixel',
      //           onAccept: () => {
                  
      //           },
      //           onReject: () => {
                  
      //           },
      //           cookies: [
      //             {
      //               name: /_fbp/,
                    
      //             }
      //             ]
      
      //         },
      //         HotJar: {
      //           // enabled: true,
      //           label: 'HotJar',
      //           onAccept: () => {
                  
      //           },
      //           onReject: () => {
                  
      //           },
      //           cookies: [
      //             {
      //               name: /_hjr/,
                    
      //             }
      //             ]
      
      //         },
      //         // GoogleTagManager: {
      //         //   label: 'Google Tag Manager',
      //         //   onAccept: () => {
      //         //     
      //         //   },
      //         //   onReject: () => {
                  
      //         //   },
      //         //   cookies: [
      //         //     {
      //         //       name: /_ga/,
                    
      //         //     }
      //         //     ]
      
      //         // },
      // }
        
      //   }
    },

    language: {
        default: 'en',
        translations: {
            'en': {
                consentModal: {
                    title: 'Cookie Management',
                    description: 'At ukreate we only use cookies for the basic functioning of the website and to enhance your online experience. For more details relative to cookies and other sensitive data, please read our privacy policy. ',
                    acceptAllBtn: 'Accept',
                    //acceptNecessaryBtn: 'Reject all',
                    showPreferencesBtn: 'Manage Individual preferences'
                },
                preferencesModal: {
                    title: 'Manage cookie preferences',
                    acceptAllBtn: 'Accept',
                    // acceptNecessaryBtn: 'Reject all',
                    // savePreferencesBtn: 'Accept current selection',
                    closeIconLabel: 'Close modal',
                    sections: [
                        {
                            title: 'Cookie Usage',
                            description: 'We use cookies to ensure the basic functionalities of the website and to enhance your online experience. For more details relative to cookies and other sensitive data.'
                        },
                        {
                            title: 'Strictly Necessary cookies',
                            description: 'These cookies are essential for the proper functioning of the website and cannot be disabled.',

                            //this field will generate a toggle linked to the 'necessary' category
                            linkedCategory: 'necessary',
                            cookieTable: {
                              headers: {
                                name: 'Name',
                                domain: 'Service',
                                description: 'Description',
                                expiration: 'Expiration',
                              },
                              body: [
                                {
                                  name: 'cc_cookie',
                                  domain: 'www.ukreate.com',
                                  description:
                                    'Save Users Cookie Preference',
                                  expiration: '7 months',
                                },
                                {
                                  name: 'st-hosted-token',
                                  domain: 'www.ukreate.com',
                                  description:
                                    'The Sharetribe cookie is only used to session information',
                                  expiration: '1 Month',
                                },
                                 
                                  {
                                    name: '__stripe_mid',
                                    domain: 'www.ukreate.com',
                                    description: 'Set for fraud prevention purposes and helps us assess the risk associated with an attempted transaction. ',
                                    expiration: '1 year',
                                  },
                                  {
                                    name: '__stripe_sid',
                                    domain: 'www.ukreate.com',
                                    description: 'Set for fraud prevention purposes and helps us assess the risk associated with an attempted transaction. Learn more about ',
                                    expiration: '30 minutes',
                                  },                       
                              ],
                              
                            },
                        },
                        
                        // {
                        //     title: 'Performance and Analytics',
                        //     description: 'These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.',
                        //     linkedCategory: 'analytics'
                        // },
                        {
                            title: 'More information',
                            description: 'For any queries in relation to my policy on cookies and your choices, please contact us.'
                        },
                        
                    ]
                }
            },
   
        },
        
  }
        
}




  
 
